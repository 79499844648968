import React, { ReactNode } from "react";
import cn from "classnames";
import BaseModal from "../Modal";

import styles from "./styles.module.scss";

interface IProps {
  onClose: () => void;
  children: ReactNode;
  sideContent?: ReactNode | null;
  className?: string;
}

const Modal = ({
  onClose,
  sideContent = null,
  children,
  className = "",
}: IProps) => (
  <BaseModal
    basicStyles
    onClose={onClose}
    containerClassName={styles.container}
    className={cn(styles.modal, className)}
  >
    <span
      aria-hidden
      onKeyPress={() => {}}
      className={styles.contentWrapper}
      onClick={(e) => e.stopPropagation()}
    >
      {sideContent ? (
        <div className={styles.sideContent}>{sideContent}</div>
      ) : (
        <picture className={styles.sideContent}>
          <img
            className={styles.image}
            src="https://miyagi-next-assets.s3.us-east-005.backblazeb2.com/next-assets/public/chairs.jpg"
            alt="A beautiful office"
          />
        </picture>
      )}
      <div className={styles.mainContent}>
        <button
          type="button"
          onClick={onClose}
          data-jest="modalCloseButton"
          className={styles.closeButton}
        >
          ×
        </button>
        <div>{children}</div>
      </div>
    </span>
  </BaseModal>
);

export default Modal;
