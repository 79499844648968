import PropTypes from "prop-types";
import React from "react";

import {
  SM_TWITTER,
  CONTACT_ZIP,
  SM_FACEBOOK,
  SM_LINKEDIN,
  CONTACT_CITY,
  SM_INSTAGRAM,
  CONTACT_STATE,
  CONTACT_ADDRESS,
  CONTACT_NUMBER_VALUE,
  CONTACT_NUMBER_DISPLAY,
} from "../../../../config";
import styles from "../style.module.scss";

const UpperFooter = ({ className, children }) => (
  <div className={`${styles.upper} ${className}`}>
    <div className={`inset ${styles.responsiveFlex}`}>
      <div className={styles.innerContainerCol2}>
        <address className={styles.item}>
          <h2>SquareFoot HQ</h2>
          <p className={styles.address}>
            <a href={`tel:${CONTACT_NUMBER_VALUE}`}>
              <span>{CONTACT_NUMBER_DISPLAY}</span>
            </a>
            <br />
            {CONTACT_ADDRESS}
            <br />
            {CONTACT_CITY}, {CONTACT_STATE} {CONTACT_ZIP}
          </p>
          <ul className={styles.socialMedia}>
            <li>
              <a href={SM_FACEBOOK} target="_blank" rel="noopener noreferrer">
                <picture>
                  <img
                    alt="Facebook"
                    src="https://miyagi-next-assets.s3.us-east-005.backblazeb2.com/next-assets/public/footer/facebook.png"
                    width="11"
                    height="23"
                  />
                </picture>
              </a>
            </li>
            <li>
              <a href={SM_TWITTER} target="_blank" rel="noopener noreferrer">
                <picture>
                  <img
                    alt="Twitter"
                    src="https://miyagi-next-assets.s3.us-east-005.backblazeb2.com/next-assets/public/footer/twitter.png"
                    width="23"
                    height="18"
                  />
                </picture>
              </a>
            </li>
            <li>
              <a href={SM_INSTAGRAM} target="_blank" rel="noopener noreferrer">
                <picture>
                  <img
                    alt="Instagram"
                    src="https://miyagi-next-assets.s3.us-east-005.backblazeb2.com/next-assets/public/footer/instagram.png"
                    width="23"
                    height="23"
                  />
                </picture>
              </a>
            </li>
            <li>
              <a href={SM_LINKEDIN} target="_blank" rel="noopener noreferrer">
                <picture>
                  <img
                    alt="LinkedIn"
                    src="https://miyagi-next-assets.s3.us-east-005.backblazeb2.com/next-assets/public/footer/linkedin.png"
                    width="26"
                    height="25"
                  />
                </picture>
              </a>
            </li>
          </ul>
        </address>

        <nav className={styles.item}>
          <h2>Company</h2>
          <ul>
            <li>
              <a href="/about">About</a>
            </li>
            <li className="desktop-only">
              <a href="/about/#clients">Clients</a>
            </li>
            <li>
              <a href="/welcome/meet-our-team">Team</a>
            </li>
            <li>
              <a href="/blog">Blog</a>
            </li>
          </ul>
        </nav>
      </div>
      <div className={styles.innerContainerCol2}>
        <nav className={styles.item}>
          <h2>Resources</h2>
          <ul>
            <li>
              <a
                href="/office-space-calculator"
                data-qa="footer-space-calculator-link"
              >
                Space Calculator
              </a>
            </li>
            <li>
              <a href="/commutes">Commute Calculator</a>
            </li>
            <li>
              <a href="/leasopedia">Leasopedia</a>
            </li>
            <li className="desktop-only">
              <a href="/leasopedia/#office-search-guide">Office Search Guide</a>
            </li>
            <li>
              <a href="/move-in-services">Move in Services</a>
            </li>
          </ul>
        </nav>

        <nav className={styles.item}>
          <h2>Connect</h2>
          <ul>
            <li>
              <a href="/contact-us">Contact</a>
            </li>
            <li>
              <a href="/jobs">Careers</a>
            </li>
            <li>
              <a href="/referrals">Referral</a>
            </li>
            <li>
              <a href="/contact-us/#list-with-us">List Your Property</a>
            </li>
          </ul>
        </nav>
      </div>
      <div className={styles.innerContainerCol1}>{children}</div>
    </div>
  </div>
);

UpperFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

UpperFooter.defaultProps = {
  children: null,
  className: "",
};

export default UpperFooter;
