import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { useActiveCampaign, useCurrentUser } from "hooks";

import HeaderLogo from "./Logo";
import styles from "./style.module.scss";

function LoggedInHeader({ className }) {
  const router = useRouter();
  const { currentUser } = useCurrentUser();
  const { data: activeCampaignData } = useActiveCampaign();

  const showCampaignHeader =
    activeCampaignData?.activeCampaign &&
    currentUser?.isBroker &&
    typeof window !== "undefined" &&
    router.pathname.match(/^\/tourbook\//);

  return (
    <header className={`inset-layout ${styles.container} ${className}`}>
      <div className={styles.logoContainer}>
        {/* When '/tourbook/campaigns' is in miyagi-next, use next/link */}
        {showCampaignHeader ? (
          <a
            href={`${process.env.NEXT_PUBLIC_MIYAGI_LEGACY_URL}/tourbook/campaigns`}
          >
            <picture>
              <img
                alt="squarefoot"
                title="SquareFoot"
                src="https://miyagi-next-assets.s3.us-east-005.backblazeb2.com/next-assets/public/brand-mark.svg"
                width="30"
                height="28"
              />
            </picture>
          </a>
        ) : (
          <HeaderLogo />
        )}

        {showCampaignHeader && (
          <a
            href={`${process.env.NEXT_PUBLIC_MIYAGI_LEGACY_URL}/tourbook/campaigns/${activeCampaignData?.activeCampaign.uuid}`}
          >
            <h1>
              {activeCampaignData?.activeCampaign.displayName}&rsquo;s Campaign
            </h1>
          </a>
        )}
      </div>

      <div className={styles.navigationContainer}>
        <input
          type="checkbox"
          id="toggle-header-menu"
          className={styles.toggleButton}
        />
        <a href="tel:+19179941062" className={styles.phoneButton}>
          <span className="icon-phone" />
        </a>

        <nav className={styles.nav}>
          <ul>
            <a
              style={{ padding: 0 }}
              href={`${process.env.NEXT_PUBLIC_MIYAGI_LEGACY_URL}/settings`}
            >
              {currentUser?.displayName}
            </a>
            <a
              className={styles.cogButton}
              href={`${process.env.NEXT_PUBLIC_MIYAGI_LEGACY_URL}/settings`}
            >
              <span className={`icon-cog ${styles.iconCog}`} />
            </a>
          </ul>
        </nav>
      </div>
    </header>
  );
}

LoggedInHeader.propTypes = {
  className: PropTypes.string,
};

LoggedInHeader.defaultProps = {
  className: null,
};

export default LoggedInHeader;
